import { Link, useParams } from 'react-router-dom';
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Image,
  Rate,
  Row,
  Space,
  Statistic,
  Typography,
} from 'antd';
import {
  ArrowsAltOutlined,
  DollarOutlined,
  DownloadOutlined,
  ScissorOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import { formDateString, getScreenWidth } from './Helpers';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer } from '@fortawesome/free-solid-svg-icons';
import { ArticleContext } from '../constants/ArticleContext';
import DocumentTitle from 'react-document-title';

const ModelsArticle = () => {
  const { slug } = useParams();
  const { articles } = useContext(ArticleContext);
  const article = articles.find(article => article.slug === slug);

  return (
    <DocumentTitle title={article?.title + ' - Ryan Hunter' || 'Ryan Hunter'}>
      <div>
        <Space direction='vertical'>
          <Breadcrumb
            items={[
              {
                title: <Link to='/'>Home</Link>,
              },
              {
                title: <Link to='/models'>Model Making</Link>,
              },
              {
                title: article?.title,
              },
            ]}
          />
          <Typography.Title>{article?.title}</Typography.Title>
          <Typography.Text type='secondary'>{formDateString(article?.date)}</Typography.Text>
          <Typography.Text type='secondary'>By Ryan Hunter</Typography.Text>
        </Space>

        <Divider />

        <Flex wrap='wrap' gap='10px'>
          <Image.PreviewGroup>
            {article?.images.map(image => (
              <Image width={getScreenWidth() < 768 ? '100%' : '30%'} key={image} src={image} />
            ))}
          </Image.PreviewGroup>
        </Flex>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <Button
            href={article?.fullResLink}
            target='_blank'
            type='primary'
            icon={<DownloadOutlined />}
          >
            View Full Resolution Images
          </Button>
        </div>

        <Divider />

        <Row gutter={16}>
          <Col xl={8} lg={8} md={8} sm={24} xs={24}>
            <Card
              title={article?.name}
              actions={[
                <Button href={article?.link} target='_blank'>
                  Learn More
                </Button>,
              ]}
            >
              <Statistic prefix={<DollarOutlined />} title='Price' value={article?.price} />
              <Statistic prefix={<ArrowsAltOutlined />} title='Scale' value={article?.scale} />
              <Statistic prefix={<ScissorOutlined />} title='Parts' value={article?.parts} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <Typography.Text type='secondary'>Difficulty</Typography.Text>
                <Rate
                  disabled
                  character={<FontAwesomeIcon icon={faHammer} />}
                  value={article?.difficulty}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '10px',
                }}
              >
                <Typography.Text type='secondary'>My Rating</Typography.Text>
                <Rate disabled allowHalf value={article?.rating} />
              </div>
            </Card>
          </Col>

          <Col xl={16} lg={16} md={16} sm={24} xs={24}>
            {article?.award && (
              <Alert
                showIcon
                icon={<TrophyOutlined style={{ color: 'white' }} />}
                message='Award Winner'
                description={article?.award}
                style={{
                  backgroundColor: '#d89613',
                  width: 'fit-content',
                  margin: 'auto',
                  marginBottom: '15px',
                }}
              />
            )}
            {article?.body.map((line, index) => (
              <Typography.Paragraph key={index}>{line}</Typography.Paragraph>
            ))}
          </Col>
        </Row>
      </div>
    </DocumentTitle>
  );
};

export default ModelsArticle;
